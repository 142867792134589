var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.listItem),function(item,i){return [(item.route)?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:i,attrs:{"to":item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{key:(i + "-" + (item.id)),staticClass:"menu-item",class:[
          isActive && !item.submenu && 'menu-item-active',
          isExactActive && !item.submenu && 'menu-item-active',
          item.submenu && 'menu-item-submenu',
          _vm.getOpenMenu(item.submenu) && 'menu-item-open'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",class:{
            'menu-toggle': item.submenu
          },attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:item.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.name))]),(item.submenu)?_c('i',{staticClass:"menu-arrow"}):_vm._e()]),(item.submenu)?_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_vm._l((item.submenu),function(sub,index){return [(sub.route)?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(sub.show),expression:"sub.show"}],key:("sub-" + index),attrs:{"to":sub.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{key:("sub-li-" + index),staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:sub.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(sub.name))])])])]}}],null,true)}):_vm._e()]})],2)]):_vm._e()])]}}],null,true)}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
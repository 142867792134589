var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Trang chủ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('STORE_VIEW')),expression:"checkPermission('STORE_VIEW')"}],attrs:{"to":"/stores"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-supermarket"}),_c('span',{staticClass:"menu-text"},[_vm._v("Cửa hàng")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('ORDER_SOURCE_VIEW')),expression:"checkPermission('ORDER_SOURCE_VIEW')"}],attrs:{"to":"/order-source"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-package"}),_c('span',{staticClass:"menu-text"},[_vm._v("Nguồn đơn hàng")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('CUSTOMER_VIEW')),expression:"checkPermission('CUSTOMER_VIEW')"}],attrs:{"to":"/customers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v("Khách hàng")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('CATE_VIEW')),expression:"checkPermission('CATE_VIEW')"}],attrs:{"to":"/categories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-menu-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Danh mục sản phẩm")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('ROLE_VIEW')),expression:"checkPermission('ROLE_VIEW')"}],attrs:{"to":"/permissions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v("Phân quyền")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('USER_VIEW')),expression:"checkPermission('USER_VIEW')"}],attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tài khoản")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('EMPLOYEE_VIEW')),expression:"checkPermission('EMPLOYEE_VIEW')"}],attrs:{"to":"/employees"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user-outline-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v("Nhân sự")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('BRAND_VIEW')),expression:"checkPermission('BRAND_VIEW')"}],attrs:{"to":"/brands"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-bell-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Thương hiệu")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('PROVIDER_VIEW')),expression:"checkPermission('PROVIDER_VIEW')"}],attrs:{"to":"/providers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-truck"}),_c('span',{staticClass:"menu-text"},[_vm._v("Nhà cung cấp")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission('PROPERTY_VIEW')),expression:"checkPermission('PROPERTY_VIEW')"}],attrs:{"to":"/properties"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-tools-and-utensils"}),_c('span',{staticClass:"menu-text"},[_vm._v("Thuộc tính sản phẩm")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }